import React, { useMemo, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { UserService, IUserDataResponse } from '../services/user/user.service';
import { RootState } from '../../store/create-store';
import { AuthService, IUserAuthData } from '../services/auth/auth.service';
import { setAuth, setUserData } from '../../store/common/common.reducer';
import Loader from '../components/loader/loader.component';
import useAlert from '../hooks/useAlert';
import { AlertVariant } from '../providers/alert.provider';

export interface UserDataContextInterface extends Partial<IUserDataResponse> {
  auth: any | null;
  isLoggedIn: boolean;
  refreshUserData: () => void;
}

export const UserDataContext = React.createContext<UserDataContextInterface>({
  user: undefined,
  auth: null,
  isLoggedIn: false,
  diffDays: 0,
  refreshUserData: () => {},
});

const UserDataProvider = ({ children }: any) => {
  const commonState = useSelector((state: RootState) => state.common);
  const { auth, user } = commonState;

  const userService = new UserService();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { showAlert } = useAlert();

  const { search, hash = '' } = location;
  const hashParams = new URLSearchParams(hash.replace('#', '?'));
  const params = useMemo(() => new URLSearchParams(search), []);

  const token = useMemo(() => params.get('token'), []);
  const code = hashParams.get('code');

  const redirectUri = useMemo(
    () => localStorage.getItem('redirect_uri') || params.get('redirect_uri'),
    [],
  );

  useEffect(() => {
    if (token) {
      history.replace({
        ...location,
        hash: '',
        pathname: location.pathname === '/' ? '/u' : location.pathname,
      });
    }
  }, [token]);

  const {
    data: authData,
    error: authError,
    isValidating: isAuthenticating,
  } = useSWR(
    {
      key: 'performLogin',
      code,
    },
    filters =>
      filters.code
        ? new AuthService().login(
            filters.code,
            localStorage.getItem('redirect_uri') ||
              window.location.origin + location.search,
            commonState?.redirectURI?.refCode || '',
          )
        : null,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  useEffect(() => {
    if (authData || (typeof token === 'string' && token)) {
      console.log('Token==========', token);
      console.log('Auth Data=================', authData);
      dispatch(setAuth(authData || ({ token } as IUserAuthData)));
    }
  }, [authData, token]);

  const {
    data: userData,
    error: userError,
    isValidating,
    mutate,
  } = useSWR(
    { key: 'getLoggedInUser', auth },
    filters => (filters.auth ? userService.getUserData(false) : null),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  useEffect(() => {
    if (userData) {
      dispatch(setUserData(userData.user));
      if (
        !userData.user.isOnboardingCompleted ||
        !userData.user.refferedBy?._id
      ) {
        history.push({
          pathname: '/onboarding',
          search,
        });
        // go to onboarding
      }
    }
  }, [userData]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (user && userData && user._id === userData.user._id) {
      setIsLoggedIn(true);
    }
  }, [user, userData]);

  useEffect(() => {
    if (isLoggedIn && user && userData) {
      if (code) {
        if (redirectUri) {
          localStorage.removeItem('redirect_uri');
          window.location.href = redirectUri;
        }
        if (
          user.trialSubscriptionStartDateAndroid ||
          user.trialSubscriptionStartDateIOS ||
          user.trialSubscriptionStartDateWeb
        ) {
          history.push('/u');
        } else {
          history.push('/u/dashboard');
        }
      }
    }
  }, [isLoggedIn, user, userData]);

  useEffect(() => {
    if (authError) {
      history.push('/');
      console.error(authError);
      showAlert('Something went wrong', AlertVariant.ERROR);
    }
    if (userError) {
      history.push('/');
      console.error(userError);
      showAlert('Something went wrong', AlertVariant.ERROR);
    }
  }, [authError, userError]);

  const contextValue: UserDataContextInterface = useMemo(() => {
    return {
      ...userData,
      auth,
      isLoggedIn,
      refreshUserData: mutate,
    };
  }, [userData, authData, auth, mutate]);
  console.log(
    '>>>>>>>>>>>>>>>>',
    (auth && isValidating && !user) || (code && isAuthenticating && !auth),
  );
  return (
    <UserDataContext.Provider value={contextValue}>
      {(auth && isValidating && !user) ||
      (code && isAuthenticating && !auth) ? (
        <Loader showLoader />
      ) : (
        children
      )}
    </UserDataContext.Provider>
  );
};

function useUserData() {
  const userData = useContext(UserDataContext);
  return userData;
}

export { useUserData };

export default UserDataProvider;
