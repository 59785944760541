/* eslint-disable no-param-reassign */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const decodeParam = (paramString: string, key: string) => {
  return decodeURIComponent(
    paramString.replace(
      new RegExp(
        `^(?:.*[&\\?]${encodeURIComponent(key).replace(
          /[.+*]/g,
          '\\$&',
        )}(?:\\=([^&]*))?)?.*$`,
        'i',
      ),
      '$1',
    ),
  );
};

export const objectToParam = (params: any) =>
  `?${Object.keys(params)
    .map(param => `${param}=${encodeURIComponent(params[param])}`)
    .join('&')}`;

export const getIsMobile = () => {
  let isMobile = false;

  try {
    isMobile = !!(
      (window.navigator && (window as any).navigator.standalone) ||
      navigator.userAgent.match('CriOS') ||
      navigator.userAgent.match(/mobile/i) ||
      window.innerWidth < 768
    );
  } catch (ex) {
    // continue regardless of error
  }

  return isMobile;
};

export const imageURLToDataURL = (url: string): Promise<string> =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export function numberWithCommas(num: number | string): string {
  if (typeof num === 'string') num = parseFloat(num);
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberWithCommasWithoutDecimal(num: number | string): string {
  if (typeof num === 'string') num = parseInt(num, 10);
  num = Number(num)?.toFixed(0);
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getProfileImageUrl(profileImageUrl: string): string {
  return profileImageUrl &&
    profileImageUrl !== 'null' &&
    profileImageUrl !== 'undefined'
    ? profileImageUrl
    : '';
}

export function removeDuplicateBadges(arr: any) {
  const ids = arr?.map((o: any) => o.badgeId);
  return arr?.filter(
    ({ badgeId }: any, index: number) => !ids.includes(badgeId, index + 1),
  );
}
