export class HttpException extends Error {
  statusCode?: number;

  constructor(public message: string, statusCode?: number) {
    super(message);
    this.message = message || 'Something went wrong!';
    this.name = HttpException.name;
    this.statusCode = statusCode;
  }
}
