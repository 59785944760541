import React, { useContext, useState, useEffect } from 'react';
import useSWR from 'swr';
import Loader from '../components/loader/loader.component';
import { AppService } from '../services/app/app.service';

export interface AppDataContextInterface {
  serviceAvailable: boolean;
  setServiceAvailable: (value: boolean) => void;
}

export const AppDataContext = React.createContext<AppDataContextInterface>({
  serviceAvailable: false,
  setServiceAvailable: () => {},
});

const AppDataProvider = ({ children }: any) => {
  const appService = new AppService();
  const { isValidating, data } = useSWR(
    { key: 'getAppDetails' },
    () => appService.getAppDetails(),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );
  let initialAvailable = true;
  if (data?.data?.isAccessible === false) {
    initialAvailable = false;
  }
  const [serviceAvailable, setServiceAvailable] = useState(initialAvailable);

  useEffect(() => {
    setServiceAvailable(data?.data?.isAccessible);
  }, [data?.data?.isAccessible, data]);

  const contextValue: AppDataContextInterface = {
    serviceAvailable,
    setServiceAvailable,
  };

  return (
    <AppDataContext.Provider value={contextValue}>
      {isValidating ? <Loader showLoader /> : children}
    </AppDataContext.Provider>
  );
};

function useAppData(): any {
  return useContext(AppDataContext);
}

export { useAppData };

export default AppDataProvider;
