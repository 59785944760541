/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styles from './profile-badge.module.scss';
import { IUserData } from '../../services/user/user-data.interface';
import { RootState } from '../../../store/create-store';
import {
  getProfileImageUrl,
  removeDuplicateBadges,
} from '../../helpers/helpers';
import BadgeRenderer from '../badges/badge-renderer.component';
import { QuickCamLogoHeader } from '../../../assets/svg';
import { OnboardingGC } from '../../../assets/images';
import { useAppData } from '../../contexts/app-data.context';
import { Button } from '@mui/material';

interface Props {
  user?: Partial<IUserData> & { contactDetail?: any };
  additionalStyle?: string;
  showBadges?: boolean;
  showFoundingMemberDetails?: boolean;
  cardImage?: string;
  from?: any;
  badgeSize?: 'small' | 'large' | 'xSmall';
  badgeClass?: string;
  badgeWrapperClass?: string;
  handleClose: any;
}

function ProfileBadge(props: Props): JSX.Element {
  const {
    user: propsUser,
    additionalStyle = '',
    showBadges = true,
    showFoundingMemberDetails = true,
    cardImage,
    from,
    badgeSize,
    badgeClass,
    badgeWrapperClass,
    handleClose,
  } = props;
  let user:
    | (Partial<IUserData> & {
        contactDetail?: any;
        serviceAvailabilityStatus?: string;
      })
    | undefined;
  const commonState = useSelector((state: RootState) => state.common);
  const imgEl = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState(false);
  const { serviceAvailable } = useAppData();

  if (!propsUser) {
    const { user: stateUser } = commonState;
    user = stateUser;
  } else {
    user = propsUser;
  }

  const onImageLoaded = () => setLoaded(true);

  useEffect(() => {
    const imgElCurrent = imgEl.current as any;

    if (imgElCurrent) {
      imgElCurrent.addEventListener('load', onImageLoaded);
    }
    return () => imgElCurrent.removeEventListener('load', onImageLoaded);
  }, [imgEl]);

  const contactFirstName =
    user?.contactDetail?.firstName ??
    user?.contactDetail?.name?.split(' ')?.[0];

  const isExpired = () => {
    if (user?.contactDetail?.visitStartTime) {
      const exp = moment().isAfter(
        moment(user?.contactDetail?.visitStartTime).add(30, 'minutes'),
      );
      return exp;
    }
    return false;
  };

  const getReferralText = () => {
    let text = '';

    if (!serviceAvailable) {
      text = 'QuickCam is not yet available in your country.';
    } else if (user?.serviceAvailabilityStatus === 'AVAILABLE_WITH_GIFTCARD') {
      text = `${
        contactFirstName || 'Hi there'
      }, QuickCam is not yet available in your country, but with your Gift Card, you can get on the Waiting List now by signing up for a free account.Your Premium Gift Card will be activated once QuickCam is available in your country and you download the app.`;
    } else if (!isExpired()) {
      if (user?.giftcardIds?.length) {
        text = `Hi ${contactFirstName}, ${
          user?.publicDisplayName?.split(' ')?.[0]
        } has a Premium Gift Card
            reserved for you to extend your FREE TRIAL to 7 days by signing up within the 30 minutes timer.`;
      } else {
        text = `${
          contactFirstName ? `${contactFirstName},` : 'Hi Friend!'
        } Earn a Premium Gift Card to extend your FREE TRIAL to 7 days by signing up within the 30 minute timer.`;
      }
    } else if (user?.giftcardIds?.length) {
      text = `Hi ${contactFirstName}, ${
        user?.publicDisplayName?.split(' ')?.[0]
      } has a Premium Gift Card
            reserved for you to extend your FREE TRIAL to 7 days by signing up.`;
    } else {
      text = `${contactFirstName}, Signup now and as a bonus you will receive 40 gift cards to share with your contacts!`;
    }
    return text;
  };

  const getOfferText = () => {
    let text = '';
    if (!isExpired()) {
      if (user?.giftcardIds?.length) {
        text = `As a BONUS, you’ll get 60 more Premium Gift Cards to share with your contacts!`;
      } else {
        text = `As a BONUS, you’ll get 60 more Premium Gift Cards to share with your contacts!`;
      }
    }
    return '';
    // return text;
  };

  return (
    <div className={styles.container}>
      {from === 'quickstarter' ? (
        <div className={styles.profilePictureContainer}>
          <div
            className={`${styles.profilePicDiv} ${additionalStyle}`}
            style={{
              backgroundImage: `url('/assets/images/founding_member.png')`,
            }}
          >
            {cardImage || user?.profileImageURL || QuickCamLogoHeader ? (
              <img
                src={getProfileImageUrl(
                  cardImage || user?.profileImageURL || QuickCamLogoHeader,
                )}
                ref={imgEl}
                className={styles.internalDiv}
                style={
                  loaded ? { display: 'inline-block' } : { display: 'none' }
                }
                alt={user?.channelName}
              />
            ) : null}
            {!loaded && (
              <img
                src={getProfileImageUrl(QuickCamLogoHeader)}
                className={styles.internalDiv}
                alt={user?.channelName}
              />
            )}
          </div>
          {showFoundingMemberDetails && (
            <>
              <h6 className={styles.channelName}>
                @
                {user?.channelDisplayName ||
                  user?.channelName ||
                  user?.channelId}
              </h6>
              <p className={styles.memberSinceMd}>
                Joined {moment(user?.created).format('MMM. DD, YYYY')}
              </p>
            </>
          )}
          {showBadges && (
            <div
              className={[styles.badgesQuickStarter, badgeWrapperClass].join(
                ' ',
              )}
            >
              {(removeDuplicateBadges(user?.badges) || []).map((badge: any) => (
                <BadgeRenderer
                  badge={badge}
                  from={from}
                  badgeSize={badgeSize}
                  badgeClass={badgeClass}
                />
              ))}
            </div>
          )}
        </div>
      ) : from !== 'referral' ? (
        <div className={styles.profilePictureContainer}>
          <div
            className={`${styles.profilePicDiv} ${additionalStyle}`}
            style={{
              backgroundImage: `url('/assets/images/founding_member.png')`,
            }}
          >
            {cardImage || user?.profileImageURL || QuickCamLogoHeader ? (
              <img
                src={getProfileImageUrl(
                  cardImage || user?.profileImageURL || QuickCamLogoHeader,
                )}
                ref={imgEl}
                className={styles.internalDiv}
                style={
                  loaded ? { display: 'inline-block' } : { display: 'none' }
                }
                alt={user?.channelName}
              />
            ) : null}
            {!loaded && (
              <img
                src={getProfileImageUrl(QuickCamLogoHeader)}
                className={styles.internalDiv}
                alt={user?.channelName}
              />
            )}
          </div>
          {showFoundingMemberDetails && (
            <>
              <p className={styles.memberSince}>
                Joined {moment(user?.created).format('MMM. DD, YYYY')}
              </p>
              <h6 className={styles.channelName}>
                @
                {user?.channelDisplayName ||
                  user?.channelName ||
                  user?.channelId}
              </h6>
            </>
          )}
          {showBadges && (
            <div className={styles.badges}>
              {(removeDuplicateBadges(user?.badges) || []).map((badge: any) => (
                <BadgeRenderer
                  badge={badge}
                  from={from}
                  badgeSize={badgeSize}
                  badgeClass={badgeClass}
                />
              ))}
            </div>
          )}
          <div className={styles.socialIconsAlign}>
            {user?.socialPlatforms?.includes('facebook') ? (
              <div className={styles.connectedPlatform}>
                <img
                  src="/assets/images/facebook_icon.png"
                  alt="Facebook"
                  className={styles.platform}
                />
                <img
                  src="/assets/images/available.svg"
                  alt=""
                  className={styles.check}
                />
              </div>
            ) : null}
            {user?.socialPlatforms?.includes('snapchat') ? (
              <div className={styles.connectedPlatform}>
                <img
                  src="/assets/images/snapchat_icon.png"
                  alt="Snapchat"
                  className={styles.platform}
                />
                <img
                  src="/assets/images/available.svg"
                  alt="available"
                  className={styles.check}
                />
              </div>
            ) : null}
            {user?.socialPlatforms?.includes('twitter') ? (
              <div className={styles.connectedPlatform}>
                <img
                  src="/assets/images/twitter_icon.png"
                  alt="Twitter"
                  className={styles.platform}
                />
                <img
                  src="/assets/images/available.svg"
                  alt=""
                  className={styles.check}
                />
              </div>
            ) : null}
          </div>
          {user.isShowFlags && (
            <div className={styles.flagDiv}>
              {user.userStateFlags?.map(flag => {
                countyFlagMap[flag.countryCode] = countyFlagMap[
                  flag.countryCode
                ]
                  ? countyFlagMap[flag.countryCode] + 1
                  : 1;
                return [
                  flag.stateCode ? (
                    <div className={styles.flagInternalDiv}>
                      <img
                        src={`/assets/flags/state/flag_${flag.stateCode.toLowerCase()}.svg`}
                        alt=""
                      />
                      <p className={styles.flagName}>
                        {flag.state.toUpperCase()}
                      </p>
                    </div>
                  ) : null,
                  countyFlagMap[flag.countryCode] === 1 ? (
                    <div className={styles.flagInternalDiv}>
                      <img
                        src={`/assets/flags/country/flag_${flag.countryCode.toLowerCase()}.svg`}
                        alt=""
                      />
                      <p className={styles.flagName}>
                        {flag.country.toUpperCase()}
                      </p>
                    </div>
                  ) : null,
                ];
              })}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.profilePictureContainer}>
          <div
            className={styles.referralSection}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              className={`${styles.profilePicDiv1} ${additionalStyle}`}
              style={
                {
                  // backgroundImage: `url('/assets/images/founding_member.png')`,
                }
              }
            >
              <img
                src={getProfileImageUrl(
                  cardImage || user?.profileImageURL || QuickCamLogoHeader,
                )}
                ref={imgEl}
                className={styles.internalDiv1}
                style={
                  loaded ? { display: 'inline-block' } : { display: 'none' }
                }
                alt={user?.channelName}
              />
              {!loaded && (
                <img
                  src={getProfileImageUrl(QuickCamLogoHeader)}
                  className={styles.internalDiv1}
                  alt={user?.channelName}
                />
              )}
            </div>

            <div
              className={styles.referral_text_section}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p style={{ wordBreak: 'break-word', textAlign: 'center' }}>
                Your Inviter <br />{' '}
                <b>
                  <span>{user?.publicDisplayName}</span>
                </b>
                <br />
                <b>
                  <span>@{user?.channelName}</span>
                </b>
              </p>
              {showBadges && (
                <div className={styles.referral_badges}>
                  {(removeDuplicateBadges(user?.badges) || [])
                    ?.slice(0, 6)
                    .map((badge: any) => (
                      <BadgeRenderer
                        badge={badge}
                        from={from}
                        badgeSize={badgeSize}
                        badgeClass={badgeClass}
                      />
                    ))}
                  {(user?.badges?.length || 0) > 6 && (
                    <span>+{(user?.badges?.length || 0) - 5}</span>
                  )}
                </div>
              )}
            </div>
          </div>

          <div
            className={styles.referral_description}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {`Hello from your inviter, ${user?.publicDisplayName}. Dive in and explore the site and when you're ready, sign up to get started and earn 1,000 Quickcam Coins instantly!`}
            <Button
              variant="contained"
              className={styles.actionButton}
              style={{
                borderRadius: '20px',
                marginTop: '20px',
                width: '150px',
              }}
              onClick={() => handleClose()}
            >
              Enter
            </Button>
          </div>
          {/* <div className={styles.referral_container}>
              <p>
                <span className={styles.float}>
                  <div className={styles.sub}>
                    {user?.giftcardIds?.length ? (
                      <>
                        <div className={styles.giftCardChannelName}>
                          <div className={styles.label}>
                            From @{user?.channelName}
                          </div>
                        </div>
                        <div className={styles.giftCount}>
                          <div className="count">
                            {user?.giftcardIds?.length || 0}
                          </div>
                          <div>Available</div>
                        </div>
                      </>
                    ) : null}
                    <img src={OnboardingGC} alt="img" />
                  </div>
                </span>
              </p>
              <div className={styles.referral_description}>
                {getOfferText()}
              </div>
            </div> */}
        </div>
      )}
    </div>
  );
}
export default ProfileBadge;
