import React, { useContext, useState, useEffect } from 'react';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { UserService } from '../services/user/user.service';
import { RootState } from '../../store/create-store';
import Loader from '../components/loader/loader.component';

interface NotificationrDataContextInterface {
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

interface INotificationProvider {
  children: React.ReactNode;
}

export const NotificationDataContext =
  React.createContext<NotificationrDataContextInterface>({
    count: 0,
    setCount: () => {},
  });

const NotificationDataProvider: React.FC<INotificationProvider> = ({
  children,
}) => {
  const userService = new UserService();

  const [count, setCount] = useState(0);
  const commonState = useSelector((state: RootState) => state.common);
  const { auth, user } = commonState;

  const { data: countData, isValidating } = useSWR(
    { key: 'getLoggedInUserNotification', auth },
    filters => (filters.auth ? userService.fetchNotificationsCount() : null),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  useEffect(() => {
    setCount(countData?.count || 0);
  }, [countData?.count]);

  const contextValue = { count, setCount };

  return (
    <NotificationDataContext.Provider value={contextValue}>
      {auth && isValidating && !user ? <Loader showLoader /> : children}
    </NotificationDataContext.Provider>
  );
};

const useNotificationData = () => {
  const notificationData = useContext(NotificationDataContext);
  return notificationData;
};

export { useNotificationData };

export default NotificationDataProvider;
