import { Backdrop } from '@mui/material';
import React from 'react';
import styles from './loader.module.scss';
import { iconLoader } from '../../../assets/images';

function Loader({ showLoader }: { showLoader: boolean }): JSX.Element | null {
  return showLoader ? (
    <Backdrop className={styles.loaderContainer} open={showLoader}>
      {/* <CircularProgress color="primary" /> */}
      <img src={iconLoader} height={60} alt="loader" />
    </Backdrop>
  ) : null;
}

export default Loader;
